
import { computed, defineComponent, reactive, ref, watch } from "vue";
import { useHead } from "@vueuse/head";
import { useStore } from "vuex";
import { useRouterUtils } from "@/utils/mixins";

import CSpinner from "@/components/spinner.vue";
import CFormInputText from "@/components/form/input-text.vue";
import CPatientSearch from "@/views/patient/search.vue";

import { Patient } from "@/store/patient/types";

import { addMoneyMask } from "@/utils/formatters";
import { useRoute } from "vue-router";

const VExam = defineComponent({
  name: "VExam",
  components: { CSpinner, CFormInputText, CPatientSearch },
  setup() {
    useHead({ title: "Agendar exames | obmed" });
    const store = useStore();
    const route = useRoute();
    const { normalizeRouteParam } = useRouterUtils();

    const exams = computed(() => store.state.exam.list);
    const steps = ref(["Paciente", "Exame", "Estabelecimento"]);
    const currentStep = computed(() =>
      route.name?.toString() == "exam" && patient.value?.id
        ? steps.value?.findIndex((step) => step === "Exame")
        : route.name?.toString().includes("-establishment")
        ? steps.value?.findIndex((step) => step === "Estabelecimento")
        : !patient.value?.id
        ? steps.value?.findIndex((step) => step === "Paciente")
        : (steps.value?.length || 0) - 1
    );

    const search = ref("");
    const loading = reactive({ list: false });
    const patient = ref<Patient | null>(null);

    async function searchExam() {
      loading.list = true;
      await store.dispatch("searchExam", { search: search.value });
      loading.list = false;
    }

    searchExam();
    watch(search, searchExam);

    return { exams, patient, search, loading, steps, currentStep, addMoneyMask, normalizeRouteParam };
  },
});

export default VExam;
